#edit-container {
    background-color: #fefcfd;
    width: 60%;
    margin: 0 20%;
    border: solid 1px #fefcfd;
}

#edit-header {
    padding: 2%;
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-color: #f11515;
    text-align: center;
}

.edit-text {
    width: 80%;
    margin: 0 10%;
    font-size: 18px;
    text-align: center;
}

#edit-form-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    row-gap: 20px;
    width: 60%;
    margin: 2% 20%;
}

.edit-form-col {
    grid-column: span 12;
}

.edit-form-col:nth-child(2), .edit-form-col:nth-child(3) {
    grid-column: span 3;
}

#edit-review {
    resize: none;
    width: 100%;
    height: 300px;
}

#rating-label, #edit-upload-label,
#edit-review-label {
    font-size: 20px;
}

#edit-submit-btn {
    width: 20%;
    height: 60px;
    background-color: #f11515;
    border: solid 1px transparent;
    color: #fefcfd;
    font-family: "Lato", "Helvetica Neuve";
    cursor: pointer !important
}